@use "./mixins" as *;

$rpf-dark-blue: #2a3e4f;

.editor-global-nav-wrapper {
  flex: 0 1 auto;
  z-index: 2;
}

.editor-global-nav {
  display: flex;
  align-items: center;
  background-color: $rpf-dark-blue;
  block-size: 100%;
  z-index: 2;

  &__account {
    margin-inline-start: auto;
    margin-inline-end: var(--space-4);
    block-size: 100%;

    svg {
      fill: var(--rpf-white);
      margin-block: 0;
      margin-inline: 0 var(--space-1);
    }

    .dropdown-button {
      padding: 0 var(--space-3);

      &:hover {
        svg {
          fill: var(--rpf-white);
        }
      }
    }

    img {
      block-size: var(--space-5);
      margin: var(--space-0-5) 0;
    }
  }

  &__home {
    display: flex;
    align-items: center;
    margin-inline-start: var(--space-4);
    padding: var(--space-1) 0;
    font-weight: var(--font-weight-bold);
    text-decoration: none;
    color: var(--rpf-white);

    span {
      margin-inline-start: var(--space-3);
    }

    &:visited {
      color: var(--rpf-white);
    }

    @include md-width {
      margin-inline-start: var(--space-6);
    }
  }
}

.--dark {
  .editor-global-nav {
    background-color: var(--rpf-grey-850);
  }
}
