.error-message {
  color: #7e0305;
  background-color: #fde2e1;
  padding: var(--space-1-5) var(--space-2);

  &__content {
    padding: 0;
    margin: 0;
    white-space: pre-wrap;
    word-break: break-word;
  }
}
