html,
body {
  margin: 0;
}

:host {
  font-size: 16px;
}

// #root {
//   height: 100%;
//   min-height: -moz-available;
//   min-height: -webkit-stretch;
//   min-height: stretch;
//   width: 100%;
//   min-width: -moz-available;
//   min-width: -webkit-stretch;
//   min-width: stretch;
// }

body {
  font-family: "Roboto", -apple-system, BlinkMacSystemFont, "Segoe UI", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

#app {
  min-block-size: 100dvh;
}

.c-editor {
  display: flex;
  flex-direction: column;
  height: 100%;
  max-height: -moz-available;
  max-height: -webkit-stretch;
  max-height: stretch;
}

.c-editor__wc {
  container-name: c-editor;
  container-type: normal;
  // display: flex;
  height: 100%;
  width: 100%;
  min-height: -moz-available;
  min-height: -webkit-stretch;
  min-height: stretch;
  min-width: -moz-available;
  min-width: -webkit-stretch;
  min-width: stretch;
  overflow: auto;
  -ms-overflow-style: none; /* Remove scrollbar for IE and Edge */
  scrollbar-width: none; /* Remove scroll bar for Firefox */
}

.--light {
  --editor-color-layer-1: var(--rpf-teal-100);
  --editor-color-layer-2: var(--rpf-white);
  --editor-color-layer-3: var(--rpf-white);
  --editor-color-outline: var(--rpf-grey-150);
  --editor-color-theme: var(--rpf-teal-800);
  --editor-color-theme-secondary: var(--rpf-teal-900);
  --editor-color-theme-tertiary: var(--rpf-teal-100);
  --editor-color-text: var(--rpf-text-primary);
  --editor-color-text-secondary: var(--rpf-text-secondary);
}

.--dark {
  --editor-color-layer-1: var(--rpf-grey-850);
  --editor-color-layer-2: var(--rpf-grey-800);
  --editor-color-layer-3: var(--rpf-grey-700);
  --editor-color-outline: var(--rpf-grey-600);
  --editor-color-theme: var(--rpf-teal-400);
  --editor-color-theme-secondary: var(--rpf-teal-400);
  --editor-color-theme-tertiary: var(--rpf-teal-900);
  --editor-color-text: var(--rpf-white);
  --editor-color-text-secondary: var(--rpf-text-secondary-darkmode);
}

.btn,
.rpf-button,
.btn.rpf-button {
  --rpf-button-background-color: var(--rpf-teal-800);
  --rpf-button-background-color-hover: var(--rpf-teal-900);
  --rpf-button-background-color-active: var(--rpf-teal-600);
  --rpf-button-background-color-disabled: var(--rpf-teal-200);
  --rpf-button-text-color: var(--rpf-black);

  --rpf-button-primary-background-color: var(--rpf-teal-800);
  --rpf-button-primary-background-color-focus: var(--rpf-teal-800);
  --rpf-button-primary-background-color-hover: var(--rpf-teal-900);
  --rpf-button-primary-background-color-active: var(--rpf-teal-600);
  --rpf-button-primary-background-color-disabled: var(--rpf-teal-200);
  --rpf-button-primary-color-disabled: var(--rpf-grey-600);
  --rpf-button-primary-text-color: var(--rpf-black);

  --rpf-button-secondary-background-color: var(--rpf-teal-800);
  --rpf-button-secondary-background-color-focus: var(--rpf-teal-800);
  --rpf-button-secondary-background-color-hover: var(--rpf-teal-100);
  --rpf-button-secondary-border-color-hover: var(--rpf-teal-900);
  --rpf-button-secondary-background-color-active: var(--rpf-teal-900);
  --rpf-button-secondary-background-color-disabled: var(
    --rpf-grey-50
  ); // might not exist
  --rpf-button-secondary-text-color: var(--rpf-black);

  --rpf-button-tertiary-text-color-hover: var(--rpf-grey-600);

  &--danger {
    --rpf-button-background-color: var(--rpf-alert-error);
    --rpf-button-background-color-hover: var(--rpf-alert-error);
    --rpf-button-background-color-active: var(--rpf-red-900);
    --rpf-button-text-color: var(--rpf-white);
  }
}

.rpf-progress-bar__bar {
  --color-progress-bar-complete: var(--rpf-teal-800);
}

.--dark {
  .btn,
  .rpf-button {
    --rpf-button-primary-background-color: var(--rpf-teal-400);
    --rpf-button-primary-background-color-focus: var(--rpf-teal-400);
    --rpf-button-primary-background-color-active: var(--rpf-teal-200);
    --rpf-button-primary-background-color-disabled: var(--rpf-grey-200);
    --rpf-button-primary-color-disabled: var(--rpf-grey-700);
    --rpf-button-primary-color-disabled-focus: var(--rpf-teal-400);
    --rpf-button-primary-background-color-hover: var(--rpf-teal-600);

    --rpf-button-secondary-background-color: var(--rpf-grey-800);
    --rpf-button-secondary-background-color-active: var(--rpf-teal-200);
    --rpf-button-secondary-color-disabled-background: var(--rpf-grey-700);
    --rpf-button-secondary-background-color-hover: var(--rpf-grey-600);
    --rpf-button-secondary-border-color-hover: var(--rpf-teal-400);
    --rpf-button-secondary-color-disabled: var(--rpf-grey-100);
    --rpf-button-secondary-color: var(--rpf-white);
    --rpf-button-secondary-text-color: var(--rpf-white);

    --rpf-button-tertiary-text-color-hover: var(--rpf-grey-200);
  }
}

.--light {
  .btn,
  .rpf-button {
    --rpf-button-background-color-disabled: var(--rpf-grey-100);
    --rpf-button-primary-color-disabled: var(--rpf-grey-600);
  }
}

.select-buttons__tick--selected {
  --rpf-select-buttons-tick-color: var(--rpf-black);
}

.modal-overlay {
  --rpf-input-active-border: var(--editor-color-theme);
}

.--light {
  $rpf-teal-secondary-tertiary-hover: #e7f8f7;
  $rpf-teal-secondary-tertiary-active: #dcf5f3;

  --rpf-files-list-item-active: #{$rpf-teal-secondary-tertiary-active};
  --rpf-files-list-item-hover: #{$rpf-teal-secondary-tertiary-hover};
}

.--dark {
  $rpf-grey-secondary-tertiary-hover: hsla(0deg, 0%, 100%, 10%);
  $rpf-grey-secondary-tertiary-active: hsla(0deg, 0%, 100%, 15%);

  --rpf-files-list-item-active: #{$rpf-grey-secondary-tertiary-active};
  --rpf-files-list-item-hover: #{$rpf-grey-secondary-tertiary-hover};
}
