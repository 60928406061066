.rpf-button--primary * {
  svg {
    fill: var(--rpf-button-primary-text-color);
  }
}

.rpf-button--tertiary * {
  svg {
    fill: var(--rpf-button-secondary-text-color) !important;
  }
}

.rpf-button--fit {
  inline-size: fit-content;
}

.rpf-button.rpf-button--fill {
  justify-content: center;
  inline-size: 100%;
  box-sizing: border-box;
}

.rpf-button--secondary {
  justify-content: center;
  align-items: center;
}

.rpf-button--secondary * {
  svg {
    fill: var(--rpf-text-secondary);
  }
}

.--dark * {
  .rpf-button--secondary {
    border-color: var(--rpf-teal-400);

    span {
      color: var(--rpf-white);
    }

    svg {
      fill: var(--rpf-white);
    }

    &::before {
      background-color: rgba(var(--rpf-grey-700), 1);
    }

    &:hover {
      border-color: var(--rpf-teal-400);

      &::before {
        background-color: rgba(var(--rpf-white), 0.1);
      }
    }

    &:active {
      &::before {
        background-color: rgba(var(--rpf-white), 0.15);
      }
    }

    &:disabled {
      border-color: var(--rpf-grey-150);
      background-color: inherit;

      &::before {
        background-color: inherit;
      }

      span {
        color: var(--rpf-grey-150);
      }
    }
  }

  .rpf-button--tertiary {
    color: var(--rpf-white);
    border: none;

    &:hover {
      &::before {
        background-color: rgba(var(--rpf-white), 0.1);
      }
    }

    &:active {
      &::before {
        background-color: rgba(var(--rpf-white), 0.15);
      }
    }

    &:disabled {
      background-color: inherit;

      &::before {
        background-color: inherit;
      }

      span {
        color: var(--rpf-grey-150);
      }
    }
  }
}

.--light * {
  .rpf-button--secondary {
    span {
      color: var(--rpf-text-primary);
    }

    &::before {
      background-color: var(--rpf-white);
    }

    &:hover {
      &::before {
        background-color: rgba(231, 248, 247, 1);
      }
    }

    &:disabled {
      border-color: rgba(213, 215, 220, 1);
      background-color: inherit;

      &::before {
        background-color: inherit;
      }

      span {
        color: rgba(213, 215, 220, 1);
      }
    }
  }

  .rpf-button--tertiary {
    border: none;

    &:active {
      &::before {
        background-color: rgba(220, 245, 243, 1);
      }
    }

    &:disabled {
      background-color: inherit;

      &::before {
        background-color: inherit;
      }

      span {
        color: rgba(213, 215, 220, 1);
      }
    }
  }
}

span.rpf-button__icon {
  display: flex;
}
