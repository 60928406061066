:root {
  .--light {
    --rpf-tab-button-hover: var(--rpf-grey-100);
    --rpf-tab-button-background: inherit;
    --rpf-tab-border-bottom-selected: var(--rpf-teal-900);
  }

  .--dark {
    --rpf-tab-button-hover: var(--rpf-grey-900);
    --rpf-tab-button-background: var(--rpf-grey-800);
    --rpf-tab-border-bottom-selected: var(--rpf-teal-800);
  }
}

#app,
#wc {
  .react-tabs {
    flex: 1;
    display: flex;
    flex-flow: column;
    overflow: hidden;
    position: relative;
    border-radius: 8px;

    &__tab {
      border: none;
      background-color: transparent;
      padding: 0;
      margin: 0;
      inset-block-start: 0;
      inset-block-end: 0;
      block-size: var(--space-6);
      box-sizing: border-box;
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: var(--font-weight-bold);

      svg {
        padding: 0 0 0 var(--space-1-5);
      }

      &:hover {
        text-decoration: underline;
      }

      &--selected {
        border-radius: 0;
        padding-block-start: var(--space-0-5);

        &:hover {
          text-decoration: none;
        }

        &:focus-visible {
          padding-block-start: 7px;
        }
      }

      &:focus {
        box-shadow: none;
      }

      &:focus-visible {
        outline-offset: -3px;
        outline: 3px solid var(--rpf-button-secondary-background-color-focus);
      }

      &:focus:after {
        display: none;
      }
    }

    &__tab-text {
      block-size: fit-content;
      inline-size: fit-content;
      margin: 0;
      padding: 0 var(--space-1-5);
      white-space: nowrap;

      &--split {
        padding-inline-end: 0;
      }
    }
    &__tab-close-btn {
      block-size: 100%;
      padding: var(--space-0-5);
      background-color: transparent;

      svg {
        padding: var(--space-1);
        border-radius: 8px;
      }

      &:focus-visible {
        border: 0;

        svg {
          border: 3px solid var(--rpf-button-secondary-background-color-focus);
        }
      }
    }

    &__tab-container {
      display: flex;
      block-size: fit-content;
    }

    &__tab-list {
      border-block-end: none;
      box-sizing: border-box;
      display: flex;
      overflow-y: hidden;
      margin: 0;
      padding: 0 var(--space-0-5) 0 0;
    }

    &__tab-panel--selected {
      flex: 1;
      display: flex;
      overflow-y: auto;
    }
  }

  .react-tabs {
    background-color: var(--rpf-tab-button-background);

    &__tab-container {
      border-block-end: 1px solid var(--editor-color-outline);
    }

    &__tab {
      background-color: var(--rpf-tab-button-background);
      border-inline-end: 1px solid var(--editor-color-outline);

      &--selected {
        color: var(--editor-color-text);
        background-color: var(--editor-color-layer-3);
        border-block-end: 4px solid var(--rpf-tab-border-bottom-selected);

        &:focus-visible {
          border-block-end: 7px solid var(--rpf-tab-border-bottom-selected);
        }
      }

      &-close-btn {
        &:hover {
          svg {
            background-color: var(--rpf-tab-button-hover);
          }
        }
      }
    }
  }
}
