@use '@raspberrypifoundation/design-system-core/scss/mixins/typography';

#app, #wc {
  .toast--bottom-center__message,
  .toast--top-center__message, .toast--top-center__status-message {
    min-block-size: fit-content;
    padding: var(--space-0-5) var(--space-1);
    margin: 0;
  }

  .toast--bottom-center {
    @include typography.style-0-75;
    inline-size: fit-content;
    max-inline-size: 100%;
    inset-block-end: 1em;
    padding: 4px;
    inset-inline-start: 50%;
    transform: translateX(-50%);
    white-space: nowrap;

    .Toastify__toast-icon {
      margin-block: 0;
      margin-inline: var(--space-0-5) 0;
    }
  }
  .toast--bottom-center__message, .toast--top-center__status-message {
    border-radius: 20px;
  }

  .toast--top-center {
    max-inline-size: fit-content;
    inline-size: 100%;
    font-weight: var(--font-weight-bold);
    inset-block-start: 15%;
    z-index: 1;

    .Toastify__toast-icon {
      inline-size: fit-content;
    }
  }

  .toast--top-center__message {
    background-color: var(--rpf-white);
    border-radius: 25px;
  }

  .toast--info {
    color: var(--rpf-text-information);
    border: 2px solid var(--rpf-text-information);
    svg {
      fill: var(--rpf-grey-900);
    }
    .Toastify__toast-icon {
      svg {
        fill: var(--rpf-text-information);
      }
    }
  }

  .toast--top-center {
    @media screen and (max-width: 480px) {
      left: 50%;
      transform: translateX(-50%);
    }
  }

  &.--dark {
    .toast--bottom-center__message, .toast--top-center__status-message {
      background-color: var(--rpf-white);
      color: var(--rpf-grey-900);
      svg {
        fill: var(--rpf-grey-900);
      }
    }
  }

  &.--light {
    .toast--bottom-center__message, .toast--top-center__status-message {
      background-color: var(--rpf-grey-900);
      color: var(--rpf-white);
      svg {
        fill: var(--rpf-white);
      }
    }
  }
}
