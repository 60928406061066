@use "@raspberrypifoundation/design-system-core/scss/mixins/typography";

@use "./Tabs" as *;

a {
  &:focus-visible {
    background-clip: padding-box;
    border: 3px solid var(--rpf-teal-900);
    outline: none;
  }
}

button {
  all: initial;
  border: 3px solid transparent;
  cursor: pointer;

  @extend a;
}

input {
  font-family: "Roboto", -apple-system, BlinkMacSystemFont, "Segoe UI", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
}

.--light {
  background-color: var(--editor-color-layer-1);
  color: var(--rpf-text-primary);
  caret-color: var(--rpf-black);

  .pythonrunner-input {
    color: rgb(36, 103, 236);
  }

  svg {
    fill: var(--rpf-black);
  }
}

.--dark {
  background-color: var(--rpf-grey-900);
  color: var(--rpf-white);
  caret-color: var(--rpf-white);

  .pythonrunner-input {
    color: rgb(36, 186, 236);
  }

  svg {
    fill: var(--rpf-white);
  }
}

#app {
  display: flex;
  flex-flow: column;
  font-family: "Roboto", -apple-system, BlinkMacSystemFont, "Segoe UI", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  @include typography.style-1;
}

html,
body,
#root,
#app {
  overflow: auto;
  block-size: 100%;
  block-size: -moz-available;
  block-size: -webkit-stretch;
  block-size: stretch;
  block-size: 100dvh;
}

::part(editor-root) {
  // enables the parent application to control the size of the web components root element
  display: block;
  flex: 1 1 auto;
  height: 100%;
}
