@use "@raspberrypifoundation/design-system-core/scss/mixins/typography";

.secondary-nav {
  align-items: center;
  background-color: var(--rpf-white);
  border-block: 1px solid var(--rpf-grey-150);
  box-sizing: border-box;
  display: flex;
  gap: var(--space-4);
  inline-size: 100%;
  padding-inline: var(--space-4);
  position: relative;

  @media (max-width: 600px) {
    padding-inline-start: var(--space-2);
    padding-inline-end: var(--space-1-5);
  }
}

.secondary-nav__title {
  @include typography.style-2;
  font-weight: var(--font-weight-bold);
  margin-block: var(--space-2);
  margin-inline-end: var(--space-4);
}

.secondary-nav__link {
  @include typography.style-1-5;
  align-items: center;
  color: var(--rpf-text-primary);
  display: flex;
  font-weight: var(--font-weight-bold);
  padding: var(--space-0-5);
  text-decoration: none;

  &:hover {
    .secondary-nav__link-text {
      border-block-end: 3px solid var(--rpf-grey-150);
      border-block-start: 3px solid transparent;
    }
  }

  &:focus-visible {
    border: 3px dashed var(--rpf-teal-800);
  }
}

.secondary-nav__link-text {
  border-block: 3px solid transparent;
}

.secondary-nav__link--active .secondary-nav__link-text {
  border-block-end: 3px solid var(--rpf-teal-600);
  border-block-start: 3px solid transparent;
}

.secondary-nav__authed-links {
  align-items: center;
  block-size: 100%;
  display: flex;
  position: relative;
  gap: var(--space-4);

  & > *:first-child {
    margin-inline-start: var(--space-4);
  }
}

.secondary-nav__authed-links::before {
  background-color: var(--rpf-grey-150);
  block-size: var(--space-3);
  content: "";
  display: block;
  inline-size: var(--space-0-25);
  position: absolute;
}

.secondary-nav__links {
  align-items: center;
  block-size: 100%;
  display: flex;
  gap: var(--space-4);
}

.secondary-nav__links-wrapper--mobile {
  margin-left: auto;

  .secondary-nav__menu-button {
    color: var(--rpf-black);
  }
}

.secondary-nav__links--mobile {
  background-color: var(--rpf-white);
  block-size: calc(100vh);
  border-block-start: 1px solid var(--rpf-grey-150);
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  gap: 0;
  inline-size: 100%;
  inset-block-start: 100%;
  inset-inline-start: 0;
  padding: var(--space-1-5);
  position: absolute;
  z-index: 2;

  .secondary-nav__link {
    block-size: fit-content;
    box-sizing: border-box;
    inline-size: 100%;
    padding-block: var(--space-1-5);
  }

  .secondary-nav__authed-links {
    flex-direction: column;
    inline-size: 100%;
    margin-block-start: var(--space-1-5);
    gap: 0;

    & > *:first-child {
      margin-block-start: var(--space-1-5);
      margin-inline-start: 0;
    }
  }
  .secondary-nav__authed-links::before {
    block-size: var(--space-0-25);
    inline-size: calc(100% - var(--space-1));
    inset-block-start: 0;
    inset-inline-start: var(--space-0-5);
  }
}
