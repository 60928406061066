@use "@raspberrypifoundation/design-system-core/scss/mixins/typography";

@use "./mixins" as *;

.modal-overlay {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: var(--space-10);
  z-index: 1000;
  position: fixed;
  inset: 0px;

  @include mobile-only {
    padding: var(--space-4);
  }
}

.modal-content {
  inline-size: 560px;
  max-block-size: 100%;
  border: 0;
  border-radius: 10px;
  display: flex;
  flex-direction: column;

  label,
  legend {
    font-weight: var(--font-weight-bold);
  }

  .modal-content__inputs input[type="text"] {
    @include typography.style-1;
    inline-size: 100%;
    box-sizing: border-box;
    border: 2px solid transparent;
    border-radius: 12px;
    padding: var(--space-1);
    display: block;
  }

  &:focus-visible {
    border: 3px solid var(--rpf-button-secondary-background-color-focus);
    outline: none;
  }
}

.modal-content__header {
  display: flex;
  align-items: center;
  min-block-size: fit-content;
  padding: var(--space-2) var(--space-1) var(--space-2) var(--space-2);

  button {
    margin-inline-start: auto;
    block-size: fit-content;
  }
}

.modal-content__heading {
  @include typography.style-2(bold);
  margin-block: var(--space-1);
  margin-inline: var(--space-1) 0;
}

.modal-content__body {
  padding: var(--space-3);
  display: flex;
  flex-direction: column;
  row-gap: var(--space-1);
  overflow-y: auto;
}

.modal-content__panel {
  padding: var(--space-2);
  background: var(--rpf-off-white);
  border-radius: var(--border-radius);

  > :first-child {
    margin-block-start: 0;
  }

  > :last-child {
    margin-block-end: 0;
  }
}

.modal-content__subheading {
  @include typography.style-1;
  margin: 0;
  font-weight: var(--font-weight-bold);
}

.modal-content__text {
  margin: 0;
  line-height: var(--line-height-1-5);
}

.modal-content__help-text {
  margin: var(--space-0-5) 0;
  font-weight: var(--font-weight-regular);
}

.modal-content__inputs {
  display: flex;
  flex-direction: column;
  gap: var(--space-4);
}

.modal-content__input-section {
  display: flex;
  flex-direction: column;
  gap: var(--space-1);
}

.modal-content__buttons {
  padding: var(--space-2);
  display: flex;
  flex-wrap: wrap;
  flex-direction: row-reverse;
  gap: var(--space-2);
  justify-content: flex-start;

  @include mobile-only {
    display: flex;
    flex-direction: column;
  }

  button,
  a {
    margin: 0;

    span {
      inline-size: 100%;
    }

    @include mobile-only {
      inline-size: 100%;
    }
  }
}

.modal-content__links {
  text-align: center;
  margin-block: var(--space-1) var(--space-0-5);
  margin-inline: 0;

  button {
    text-decoration: underline;
  }
}

hr {
  border: none;
  border-top: 1px solid var(--rpf-grey-100);
  margin-block: var(--space-3);
}

.--dark {
  .modal-overlay {
    background-color: rgba(0, 0, 0, 0.5);

    .modal-content__inputs input {
      border: 2px solid var(--rpf-white);
      background-color: var(--rpf-grey-700);
      color: inherit;

      &:focus-visible {
        border: 2px solid var(--rpf-input-active-border);
        outline: none;
      }
    }
  }
  .modal-content {
    background-color: var(--rpf-grey-700);
  }

  .modal-content__header {
    border-block-end: 1px solid var(--rpf-grey-500);
  }

  .modal-content__help-text {
    color: var(--rpf-text-secondary-darkmode);
  }

  .modal-content__buttons {
    border-block-start: 1px solid var(--rpf-grey-500);
  }
}

.--light {
  .modal-overlay {
    background-color: rgba(67, 69, 76, 0.5);

    .modal-content__inputs input {
      border: 2px solid var(--rpf-grey-100);

      &:focus-visible {
        border: 2px solid var(--rpf-input-active-border);
        outline: none;
      }
    }
  }

  .modal-content {
    background-color: var(--rpf-white);
  }

  .modal-content__header {
    border-block-end: 1px solid var(--rpf-grey-150);
  }

  .modal-content__help-text {
    color: var(--rpf-text-secondary);
  }

  .modal-content__buttons {
    border-block-start: 1px solid var(--rpf-grey-150);
  }

  .modal-overlay .password-field__button {
    border-color: var(--rpf-grey-100);
    &:active {
      &::before {
        background-color: transparent;
      }
    }
  }

  .modal-content .password-field__input {
    border-start-end-radius: 0;
    border-end-end-radius: 0;
  }
}
