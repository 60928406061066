.btn {
  align-items: center;
  border-radius: var(--space-1);
  border: 3px solid transparent;
  box-sizing: border-box;
  cursor: pointer;
  display: inline-flex;
  font-family: sans-serif;
  font-size: inherit;
  font-weight: var(--font-weight-bold);
  gap: var(--space-1);
  justify-content: center;
  min-block-size: 44px;
  min-inline-size: 44px;
  padding: 10px;
  position: relative;
  text-align: center;
  text-decoration: none;

  &:disabled {
    cursor: default;
  }

  &:focus-visible {
    background-clip: padding-box;
    border: 2px solid transparent;
    outline: none;
  }

  &--primary {
    background-color: var(--rpf-button-primary-background-color);
    border-radius: var(--space-1);

    color: var(--rpf-button-primary-text-color);

    svg {
      fill: var(--rpf-button-primary-text-color);
    }

    &:active,
    .btn-outer:active & {
      background-color: var(--rpf-button-primary-background-color-active);
    }

    &:focus-visible,
    .btn-outer:focus-visible & {
      background-color: var(--rpf-button-primary-background-color-focus);
    }

    &:hover,
    .btn-outer:hover & {
      background-color: var(--rpf-button-primary-background-color-hover);
      border-radius: var(--space-1);
    }

    &:disabled {
      background-color: var(--rpf-button-primary-background-color-disabled);
      color: var(--rpf-button-primary-color-disabled);

      svg {
        fill: var(--rpf-button-primary-color-disabled);
      }

      &:hover,
      .btn-outer:hover & {
        background-color: var(--rpf-button-primary-background-color-disabled);
      }
    }
  }

  &--secondary {
    background-color: inherit;
    border: 2px solid var(--rpf-button-primary-background-color);
    color: var(--rpf-button-secondary-text-color);

    svg {
      fill: var(--rpf-button-secondary-text-color);
    }

    &:active,
    .btn-outer:active & {
      background-color: inherit;
    }

    &:focus-visible,
    .btn-outer:focus-visible & {
      background-color: inherit;
    }

    &:hover,
    .btn-outer:hover & {
      background-color: inherit;
    }

    &:disabled {
      background-color: var(--rpf-button-secondary-background-color-disabled);
      color: var(--rpf-button-secondary-background-color-active);

      svg {
        fill: var(--rpf-button-secondary-background-color-active);
      }

      &:hover,
      .btn-outer:hover & {
        background-color: var(--rpf-button-secondary-background-color-disabled);
      }
    }

    &:active {
      border: 2px solid var(--rpf-button-secondary-background-color-active);
    }

    &:hover {
      background-color: var(--rpf-button-secondary-background-color-hover);
      color: var(--rpf-button-secondary-text-color-hover);
      border-color: var(--rpf-button-secondary-border-color-hover) !important;

      svg {
        fill: var(--rpf-button-secondary-text-color-hover);
      }
    }

    &:focus-visible {
      outline: 3px solid var(--rpf-button-secondary-background-color-focus);
    }
  }

  &--tertiary {
    background-color: inherit;

    color: inherit;

    &:active,
    .btn-outer:active & {
      background-color: inherit;
    }

    &:focus-visible,
    .btn-outer:focus-visible & {
      background-color: inherit;
    }

    &:hover,
    .btn-outer:hover & {
      background-color: inherit;
    }

    &:disabled {
      background-color: inherit;
      color: var(--rpf-grey-600);

      svg {
        fill: var(--rpf-grey-600);
      }

      &:hover,
      .btn-outer:hover & {
        background-color: inherit;
      }
    }

    &:active {
      color: var(--rpf-teal-900);

      svg {
        fill: var(--rpf-teal-900);
      }
    }

    &:hover {
      color: var(--rpf-button-tertiary-text-color-hover);

      svg {
        fill: var(--rpf-button-tertiary-text-color-hover);
      }
    }
  }

  &--small {
    min-block-size: unset;
    min-inline-size: unset;
    padding: var(--space-0-5);
  }

  &--svg-only {
    svg {
      margin: 0;
    }
  }
}

.btn-outer {
  background: transparent;
  border-radius: var(--space-1);
  cursor: pointer;
  padding: var(--space-1) 0;
  display: flex;
  align-items: center;
  justify-content: center;

  &:focus-visible {
    outline: none;
  }

  &:has(.btn:disabled) {
    cursor: default;
  }
}
